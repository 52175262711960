export const TRANSACTIONS_TYPES = {
  REFUND: 'refund',
  REFUND_FREE_BET: 'refund_free_bet',
  DEPOSIT: 'deposit',
  WITHDRAW: 'withdraw',
  PRIZE: 'prize',
  INSCRIPTION: 'inscription',
  INSCRIPTION_FREE_BET: 'inscription_free_bet',
  INSCRIPTION_ROULETTE: 'inscription_roulette',
  FRIEND_CODE_PROMO: 'friend_code_promo',
  BUY: 'buy',
  CONVERSION_PROMO: 'conversion_promo',
};

export const DEPOSIT_METHODS = {
  PAYPAL: 'PAYPAL',
  REDSYS: 'REDSYS',
};

export const REDSYS_METHODS = {
  CARD: 'card',
  BIZUM: 'bizum',
  GOOGLE_PAY: 'google',
  GOOGLE_APPLE_PAY: 'xpay',
};

export const IDENTITY_VERIFICATION_TYPES = {
  PAYPAL: 'paypal',
  BANK: 'bank',
};
