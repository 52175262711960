
  import Vue from 'vue';
  import PwaDepositBanner from "@/components/deposit/PwaDepositBanner.vue";
  import PwaIcon from "@/components/common/PwaIcon.vue";
  import PwaPrice from "@/components/common/PwaPrice.vue";
  import PwaSlider from "@/components/common/PwaSlider.vue";
  import PwaFreeBetCard from "@/components/PwaFreeBetCard.vue";
  import {TRANSACTIONS_TYPES} from "@/utils/transactions";
  import {MODAL_IDS} from "@/utils/modal";
  import {ROUTES} from "@/router/routes";
  import pageMixin from "@/mixins/page";
  import {BreadcrumbItems, BreadcrumbItemType} from "@/utils/breadcrumb";
  import {UserActionTypes} from "@/store/modules/user/actions";
  import {EVENTS} from "@/utils/events";
  import { COMPETITION_TYPE_IDS } from '@/utils/competition';

  export default Vue.extend({
    name: "Wallet",
    mixins: [pageMixin],
    components: {
      PwaDepositBanner,
      PwaIcon,
      PwaPrice,
      PwaSlider,
      PwaFreeBetCard,
      PwaCrossSellingBanner: () => import('@/components/PwaCrossSellingBanner.vue'),
    },
    data() {
      return {
        userWallet: {} as {quantity?: number},
        transactions: [],
        hasMoreTransactions: false,
        limit: 10,
        offset: 0,
        competitionsFreeInscriptions: [],
        hasDepositPromo: false,
        bannerData: {} as any,
        breadcrumb: [{labelId: BreadcrumbItems.wallet.labelId}] as BreadcrumbItemType[],
      }
    },
    mounted() {
      this.getWalletPage(false);
      this.$root.$on(EVENTS.RELOAD_WALLET_PAGE, () => this.getWalletPage(false));
    },
    beforeDestroy(): void {
      this.$root.$off(EVENTS.RELOAD_WALLET_PAGE);
    },
    methods: {
      async getWalletPage(paginate: boolean) {
        this.showLoader(true);
        try {
          const {data} = await this.$http.page.getWalletPage({
            limit: this.limit,
            offset: this.offset
          });
          this.userWallet = data.data.user_wallet;
          this.$store.dispatch(UserActionTypes.SET_USER_WALLET_QUANTITY, this.userWallet.quantity);
          let transactions: Record<string, any>[] = data.data.transactions;
          if (this.transactions && paginate) {
            transactions.forEach(transaction => this.transactions.push(transaction));
          } else {
            this.transactions = transactions;
          }
          this.hasMoreTransactions = (transactions.length >= this.limit);
          this.competitionsFreeInscriptions = data.data.competitions_free_inscriptions || [];
          this.hasDepositPromo = data.data.has_deposit_promo || false;
          this.bannerData = data.data.banner_data || {};
        } catch (error) {
          this.showToastError(this.$t('139'), this.$t('140'));
        } finally {
          if(this.$route.query.Ds_MerchantParameters){
            const Ds_MerchantParameters = this.$route.query.Ds_MerchantParameters;
            const Ds_Signature = this.$route.query.Ds_Signature;
            if (this.isDesktop) {
              this.showModalById(MODAL_IDS.DEPOSIT);
            } else {
              this.$router.push({name: ROUTES.deposit.name, params: {lang: this.routeLang}, query: {Ds_MerchantParameters, Ds_Signature}});
            }
          }
          this.showLoader(false);
        }
      },
      deposit() {
        if (this.isDesktop) {
          this.showModalById(MODAL_IDS.DEPOSIT);
        } else {
          this.$router.push({name: ROUTES.deposit.name, params: {lang: this.routeLang}});
        }
      },
      withdraw() {
        if (this.isDesktop) {
          this.showModalById(MODAL_IDS.WITHDRAW);
        } else {
          this.$router.push({name: ROUTES.withdraw.name, params: {lang: this.routeLang}});
        }
      },
      onSeeMoreClick() {
        if (this.hasMoreTransactions) {
          this.offset = this.offset + this.limit;
          this.getWalletPage(true);
        }
      },
      isTransactionIncome(transaction:any) {
        const type = transaction.type.toLowerCase();
        const quantity = transaction.quantity;

        const { INSCRIPTION, INSCRIPTION_FREE_BET, INSCRIPTION_ROULETTE, WITHDRAW, BUY, REFUND } = TRANSACTIONS_TYPES;
        return (!(type == INSCRIPTION || type == INSCRIPTION_FREE_BET || type == INSCRIPTION_ROULETTE || type == WITHDRAW || type == BUY || (type == REFUND && quantity < 0)));
      },
      getAmountStyle(transaction: any) {
        let color = !this.isTransactionIncome(transaction) ? 'loss' : 'income';

        return 'text-align-right amount ' + color;
      },
      goToCompetitionPage(competitionId: string, competitionTypeId: number) {
        let namePath = ROUTES.competition.name;
        if(competitionTypeId === COMPETITION_TYPE_IDS.MULTIPLAYER){
          namePath = ROUTES.multiplayer.name;
        }else if(competitionTypeId === COMPETITION_TYPE_IDS.RANKED){
          namePath = ROUTES.ranked.name;
        }

        this.$router.push({name: namePath, params: {lang: this.routeLang, competitionId}});
      },
      getTransactionTypeText(transaction: any): string {
        const type = transaction.type.toLowerCase();
        const status = transaction.status;
        const quantity = transaction.quantity;
        const competitionId = transaction.competition_id;

        let text = '';
        let key = '';
        const {REFUND, REFUND_FREE_BET, DEPOSIT, WITHDRAW, PRIZE, INSCRIPTION, INSCRIPTION_FREE_BET, INSCRIPTION_ROULETTE, FRIEND_CODE_PROMO, BUY, CONVERSION_PROMO} = TRANSACTIONS_TYPES;
        switch (type) {
          case REFUND:
            key = (quantity < 0 && competitionId ? '472' : '175');
            break;
          case REFUND_FREE_BET:
            key = '180';
            break;
          case DEPOSIT:
            key = '176';
            break;
          case WITHDRAW:
            if(status === 0){
              text += this.$t('213');
            }else{
              text = this.$t('177') + ' ' + this.$t('434');
            }
            break;
          case PRIZE:
            key = '178';
            break;
          case INSCRIPTION:
            key = '179';
            break;
          case INSCRIPTION_FREE_BET:
            key = '409';
            break;
          case INSCRIPTION_ROULETTE:
            key = '182';
            break;
          case FRIEND_CODE_PROMO:
            key = '181';
            break;
          case BUY:
            key = '182';
            break;
          case CONVERSION_PROMO:
            key = '708';
            break;
        }

        return key ? this.$t(key) : text;
      },
      getTransactionDataMessage(transaction: any) {
        return transaction.type.toLowerCase() === TRANSACTIONS_TYPES.DEPOSIT
          ? `${this.$t('176')} ${transaction.payment_method}`
          : transaction.trace_data;
      },
      showTransactionData(transaction: any) {
        if (!this.isDesktop) {
          this.showModal(
            {
              title: this.getTransactionTypeText(transaction),
              hideCancel: true,
              message: this.getTransactionDataMessage(transaction),
              showOnRoot: true,
            }
          );
        }
      },
      explainPromoQuantity(amount: number) {
        this.showModal(
          {
            title: this.$t("185"),
            hideCancel: true,
            message: this.$t("170", {amount: amount}),
            showOnRoot: true,
          }
        );
      },
      hasToFilterTransaction(transaction: any) {
        return transaction.type.toLowerCase() === TRANSACTIONS_TYPES.DEPOSIT && transaction.status === 0;
      }
    },
    computed: {
      getTotalFreematchAmount(): number {
        let totalFreematchAmount = 0;

        if(this.competitionsFreeInscriptions && this.competitionsFreeInscriptions.length > 0){
          this.competitionsFreeInscriptions.forEach(competitionsFreeInscription => {
            totalFreematchAmount += competitionsFreeInscription.cost_inscription;
          });
        }

        return totalFreematchAmount;
      }
    }
  })
