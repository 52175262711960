
  import Vue from "vue";
  import PwaPrice from "@/components/common/PwaPrice.vue";
  import PwaIcon from "@/components/common/PwaIcon.vue";
  import {ROUTES} from "@/router/routes";
  import {MINIGAMES_ALIAS} from "@/utils/minigames";

  export default Vue.extend({
    name: "pwa-free-bet-card",
    components: {
      PwaPrice,
      PwaIcon,
    },
    props: {
      costInscription: Number,
      accumulatedUnblock: Number,
      accumulated: Number,
      description: String,
      unblocked: Boolean,
      expirationDate: String,
    },
    computed: {
      steps(): {amount: number, completed: boolean, percentage: string}[] {
        const numSteps = 5;
        const steps: {amount: number, completed: boolean, percentage: string}[] = [];
        const stepAmount = this.accumulatedUnblock / numSteps;

        for (let i = 1; i <= numSteps; i++) {
          const amount = i === numSteps ? this.accumulatedUnblock : (stepAmount * i);
          const previousAmount = (stepAmount * (i-1));
          const completed = this.accumulated >= amount;
          let percentage = '';
          if (completed) {
            percentage = '100%';
          } else if (this.accumulated >= previousAmount) {
            percentage = `${((this.accumulated - previousAmount) / stepAmount) * 100}%`;
          } else {
            percentage = '0';
          }

          steps.push({
            amount,
            completed,
            percentage,
          });
        }

        return steps;
      },
    },
    methods: {
      onCardClick() {
        if (this.unblocked) {
          if (this.costInscription < 2.5) {
            let gameAlias = MINIGAMES_ALIAS.FLAPPY_GOAL;
            const minigames = this.$store.state.user.minigames || [];
            if(minigames.length > 0){
              gameAlias = minigames[0].alias;
            }
            this.$router.push({name: ROUTES.minigame.name, params: { lang: this.routeLang, gameAlias }});
          } else {
            this.createMatch();
          }
        }
      },
    }
  });
